import * as React from 'react';
import {Container, Grid, Input} from '@mui/material';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import ActivityCard from '../../components/Card/Activity/ActivityCard.js';
import { getActivities } from '../../services/activities/ActivitiesServices';
import { getLastDayCheckIn, registerAssistance } from '../../services/attendance/AttendanceServices';
import { useSelector } from 'react-redux';
import AlertOptions from '../../components/Alert/AlertOptions';
import BasicAlerts from '../../components/Alert/Alert';
import ButtonWithIcon from '../../components/Button/ButtonWithIcon.js';
import { formatNameCapitals, formatDateSpanish, formatJustTimeNoSeconds, formatDate, formatJustDateSpanish } from '../../constants/commonFunctions.js';
import { useLocation, useNavigate } from 'react-router-dom';
import * as ROUTES from "../../routes/routes";
import seeMoreIcon from '../../assets/icons/see-more.svg';
import noActivitiesImg from '../../assets/images/sin-actividades.png';
import { Co2Sharp } from '@mui/icons-material';
import './Activities.css';
import LocationDetailsCard from '../../components/Card/LocationDetails/LocationDetailsCard.js';
import CustomDatePicker from '../../components/CustomDatePicker/CustomDatePicker.js';


const Activities  = (props) => {
    const { state } = useLocation();
    const navigate = useNavigate();
    const { checkIn, setCheckIn } = props;
    const [isLoading, setIsLoading] = React.useState(true);
    const [activeCheckIn, setActiveCheckIn] = React.useState(null);
    const [activitiesList, setActivitiesList] = React.useState([]);
    const [alertOpen, setAlertOpen] = React.useState(false);
    const [activitySelected, setActivitySelected] = React.useState({activityId: 1, sessionId: 1, actionType: 1, time: new Date(), place: ''});
    const userInf = JSON.parse(localStorage.getItem('userPUCPIN'))
    const [alertMessage, setAlertMessage] = React.useState('');
    const [alertType, setAlertType] = React.useState('');
    const [activeMessage, setActiveMessage] = React.useState(false);
    const [firstResult, setFirstResult] = React.useState(1);
    const [lastResult, setLastResult] = React.useState(3);
    const [moreInf, setMoreInf] = React.useState(false);
    const [dateSelected, setDateSelected] = React.useState(new Date());

    const fillActivities = (date, maxResultVar, start) => {
        let day = date.getDate()
        let month = date.getMonth() + 1
        let year = date.getFullYear()
        let hour = date.getHours();
        let minute = date.getMinutes();
        let seconds = date.getSeconds();
                    
        let stringDate = `${day < 10 ? '0'+day : day}/${month < 10 ? '0'+month : month}/${year} ${hour < 10 ? '0' + hour : hour}:${minute < 10 ? '0' + minute : minute}:${seconds < 10 ? '0' + seconds : seconds}`
                        
        const variables = {
            firstResult: maxResultVar/3,
            maxResults: maxResultVar,
            user: userInf.email,
            personId: userInf.role.personId,
            date: stringDate
        };

        getActivities(variables)
            .then(function (response) {
                if(response.data.resultCode === 0 || response.data.resultCode === 1003) {
                    const activeButton = response.data.total > maxResultVar;
                    setMoreInf(activeButton);
                    setActivitiesList(start ? response.data.list : activitiesList.concat(response.data.list));
                }
            })
            .catch(function (error) {
                console.log(error);
                setAlertType("error");
                setAlertMessage("Las actividades no han sido cargadas");
                setActiveMessage(true);
            })
            .finally(() => setIsLoading(false));
    }

    React.useEffect( () => {
        setActiveCheckIn(checkIn);
    }, [checkIn]);

    React.useEffect(() => {
        if(state && state.alertType && state.alertMessage) {
            setAlertType(state.alertType);
            setAlertMessage(state.alertMessage);
            setActiveMessage(true);
        }
    }, []);

    React.useEffect(() => {
        setLastResult(3);
        setIsLoading(true);
        fillActivities(dateSelected, 3, true);
    }, [dateSelected]);

    const confirmCheckOut = (activityId, sessionId, place) => {        
        setAlertOpen(true);
    }

    const handleCheckOutError = () => {
        getLastDayCheckIn({user: userInf.email, personId: userInf.role.personId})
        .then((response) => {
            if(response.data.resultCode === 1003) {
                // No check in
                setActiveCheckIn(null);
                setCheckIn(null);
                setAlertMessage('Ya se registró su salida previamente');
                setAlertType('warning');
                setActiveMessage(true);
            }
            else {
                // New check in
                setActiveCheckIn(response.data);
                setCheckIn(response.data);
                setAlertMessage('Ya se registró su salida y un nuevo ingreso previamente');
                setAlertType('warning');
                setActiveMessage(true);
            }
            setAlertOpen(false);
        })
        .catch((error) => console.log(error))
    }

    const registerAttendance = () => {

        const variables = {
            user: userInf.email,
            type: 2,
            sessionId: checkIn.sessionId,
            buildingId: checkIn.siteId !== null ? null : checkIn.buildingId,
            siteId: checkIn.siteId
        };

        // RELASE 2 ONLY. REMOVE FOR RELEASE 3.
        if(checkIn.siteId && checkIn.siteId !== null) {

            const buildingVariables = {
                user: userInf.email,
                buildingId: checkIn.buildingId,
                type: 2,
                onlyBuilding: 0
            };

            registerAssistance(variables)
            .then(function (response) {
                // Building check-out registered
                // Now register site
                const buildingVariables = {
                    user: userInf.email,
                    sessionId: checkIn.sessionId,
                    buildingId: checkIn.buildingId,
                    type: 2,
                    onlyBuilding: 0
                }
                registerAssistance(buildingVariables)
                .then(function (response) {
                    let message = "Su salida ha sido registrada";
                    setAlertType("success");
                    setAlertMessage(message);
                    setActiveMessage(true);
                    setAlertOpen(false);

                    setCheckIn(null);
                    setActiveCheckIn(null);
                })
                .catch(function (error) {
                    if(error && error.response && error.response.data && error.response.data.resultCode === 1004) {
                        handleCheckOutError();
                    }
                    else {
                        console.log(error);
                        setAlertType("error");
                        setAlertMessage("Su asistencia no ha sido registrada. Inténtelo de nuevo");
                        setActiveMessage(true);
                    }
                });

            })
            .catch(function (error) {
                if(error && error.response && error.response.data && error.response.data.resultCode === 1004) {
                    handleCheckOutError();
                }
                else {
                    console.log(error);
                    setAlertType("error");
                    setAlertMessage("Su asistencia no ha sido registrada. Inténtelo de nuevo");
                    setActiveMessage(true);
                }
            });
        } else if(checkIn.buildingId && checkIn.buildingId !== null){

            const buildingVariables = {
                user: userInf.email,
                buildingId: checkIn.buildingId,
                type: 2,
                onlyBuilding: 1
            }
    
            registerAssistance(buildingVariables)
            .then(function (response) {
                let message = "Su salida ha sido registrada";
                setAlertType("success");
                setAlertMessage(message);
                setActiveMessage(true);
                setAlertOpen(false);
                setCheckIn(null);
                setActiveCheckIn(null);
            })
            .catch(function (error) {
                if(error && error.response && error.response.data && error.response.data.resultCode === 1004) {
                    handleCheckOutError();
                }
                else {
                    console.log(error);
                    setAlertType("error");
                    setAlertMessage("Su asistencia no ha sido registrada. Inténtelo de nuevo");
                    setActiveMessage(true);
                }
            });
        }
    }

    const canICheckIn = (currentActivity) => {
        if(checkIn && checkIn !== null)
            return false;

        return true;
    }

    const canICheckOut = (currentActivity) => {
        if(checkIn && checkIn !== null && currentActivity.activityId === checkIn.activityId)
            return true;

        return false;
    }

    React.useEffect( () => {
        if (activeMessage){
            setTimeout(() => {
                setActiveMessage(false);
            }, 5000);
        }

    }, [activeMessage]);

    const moreResults = () =>{
        const newLastResult = lastResult+3;
        setLastResult(newLastResult);
        fillActivities(dateSelected, newLastResult);
    }

    const handleActivity = (activityObj) => {
        navigate(ROUTES.ACTIVITY_DETAIL, {
                state: {
                    activity: activityObj
                }
            }
        )
    }

    const handleLocationDetail = () => {
        navigate(ROUTES.QR_PRE)
    }

    return(
        <Grid container xs={12} justifyContent='center'>
        <Grid container xs={12} sm={6}>   
            {activeMessage &&
                <BasicAlerts
                    message={alertMessage}
                    type={alertType}
                />
            }
            <Container className="containerCardsView">
                <div align='center'>
                    {/* <Typography 
                        color='primary'
                        variant="h3" 
                        gutterBottom 
                        align="justify"
                    >
                        Hola, {formatNameCapitals(userInf.name)}
                    </Typography> */}
                    <CustomDatePicker
                        title={'Hola, ' + formatNameCapitals(userInf.role.fullName).replace(',', '')}
                        setValue={setDateSelected}
                    />
                    {/* {isLoading === false && activitiesList && activitiesList.length>0 &&
                    (
                        <Typography
                        color='primary'
                        variant="body1" 
                        gutterBottom 
                        align="justify"
                        >
                            Hoy {formatJustDateSpanish(today)}, tenemos:
                        </Typography>
                    )
                    } */}
                    {activeCheckIn !== null ?
                        activeCheckIn.activityId !== null ?
                            <Grid item marginTop="15px">
                                <Typography mt='10px' gutterBottom
                                    className='activity-header'
                                    align="justify"
                                >
                                    Actividad en transcurso:
                                </Typography>
                                <LocationDetailsCard
                                    key={activeCheckIn.sessionId}
                                    activityLabel={activeCheckIn.activityDescription}
                                    typeActivity={activeCheckIn.activityType}
                                    siteName={(activeCheckIn.siPucpLabel === null ? activeCheckIn.site : activeCheckIn.siPucpLabel) + ' - ' + (activeCheckIn.shortName === null ? activeCheckIn.building : activeCheckIn.shortName)}
                                    shift={activeCheckIn.shift}
                                    role={activeCheckIn.role}
                                    noImage
                                    isActivity={activeCheckIn.isAbsent}
                                    onClick={() => handleLocationDetail()}
                                    today={formatDate(dateSelected)}
                                />
                            </Grid>
                            :
                            <Grid item marginTop="15px">
                                <Typography mt='10px' gutterBottom
                                    className='activity-header'
                                    align="justify"
                                >
                                    Actividad en transcurso:
                                </Typography>
                                    <LocationDetailsCard
                                        key={activeCheckIn.sessionId}
                                        checkIn={activeCheckIn}
                                        activityLabel={activeCheckIn.activityDescription}
                                        typeActivity={activeCheckIn.activityType}
                                        siteName={(activeCheckIn.siPucpLabel === null ? activeCheckIn.site : activeCheckIn.siPucpLabel) + ' - ' + (activeCheckIn.shortName === null ? activeCheckIn.building : activeCheckIn.shortName)}
                                        shift={activeCheckIn.shift}
                                        role={activeCheckIn.role}
                                        noImage
                                        isActivity={activeCheckIn.isAbsent}
                                        onClick={() => handleActivity(activeCheckIn)}
                                        today={formatDate(dateSelected)}
                                />
                            </Grid>
                        :
                        <>
                            <Typography mt='10px' gutterBottom
                                className='activity-header'
                                align="justify"
                            >
                                Actividades:
                            </Typography>

                            {isLoading === false && activitiesList.map( (activity, index) => {
                                return(
                                    // <ActivityCard
                                    //     activityId={activity.activityId}
                                    //     key={activity.sessionId}
                                    //     time={activity.shift}
                                    //     title={activity.activityDescription}
                                    //     site={(activity.siPucpLabel === null ? activity.site : activity.siPucpLabel) + ' - ' + (activity.shortName === null ? activity.building : activity.shortName)}
                                    //     type={activity.activityType}
                                    //     sessionId={activity.sessionId}
                                    //     register={() => { 
                                    //         navigate(ROUTES.QR_SCAN, {
                                    //         state: {
                                    //             sessionId : activity.sessionId,
                                    //             siteId : activity.siteId
                                    //         }
                                    //     }) }}
                                    //     checkIn={canICheckIn(activity)}
                                    //     checkOut={canICheckOut(activity)}
                                    //     role={activity.role}
                                    //     isAbsent={activity.isAbsent}
                                    // />
                                    <Grid item xs={12} marginBottom="10px">
                                        <LocationDetailsCard
                                            key={activity.sessionId}
                                            checkIn={activeCheckIn}
                                            activityLabel={activity.activityDescription}
                                            typeActivity={activity.activityType}
                                            siteName={(activity.siPucpLabel === null ? activity.site : activity.siPucpLabel) + ' - ' + (activity.shortName === null ? activity.building : activity.shortName)}
                                            shift={activity.shift}
                                            role={activity.role}
                                            noImage
                                            isActivity={activity.isAbsent}
                                            onClick={() => handleActivity(activity)}
                                            today={formatDate(dateSelected)}
                                        />
                                    </Grid>
                                )
                            })}
                            {isLoading === false && activitiesList && activitiesList.length===0 && 
                                <div className='noData'>
                                    <Typography
                                        color='primary'
                                        variant="h3" 
                                        gutterBottom 
                                        align="justify"
                                    >
                                        No hay actividades por el momento.
                                    </Typography>
                                    <Grid container direction="row">
                                        <Grid item xs={0} sm={3} md={4} lg={4}/>
                                        <Grid item xs={12} sm={8} md={6} lg={4} >
                                            <img
                                                className='noActivitiesImg'
                                                src={noActivitiesImg}
                                            />
                                        </Grid>
                                    </Grid>
                                </div>}
                            {activitiesList && activitiesList.length > 0 && moreInf &&
                                <Box className="activityButtonContainer">
                                    <ButtonWithIcon
                                        buttonText="Ver más" 
                                        iconButton={seeMoreIcon} 
                                        onClick={()=>moreResults()}
                                        rightIcon
                                        fullWidth
                                    />
                                </Box>
                            }
                        </>
                    }

                    <AlertOptions 
                        title="¿Desea confirmar su salida?"
                        message={"¿Desea confirmar su salida siendo las " + formatDateSpanish(activitySelected.time) + " al " + activitySelected.place + "?"}
                        alertOpen={alertOpen}
                        setAlertOpen={setAlertOpen}
                        onConfirm={registerAttendance}
                    />
                    
                </div> 
            </Container>  
        </Grid>
        </Grid>
    )

};

export default Activities;