import React from 'react';
import Auxiliar from '../../hoc/Auxiliar/Auxiliar';
import { Box, CssBaseline, Grid, Typography } from '@mui/material';
import pucpLogo from "../../assets/icons/Logo_PUCP.svg";
import pucpInCaps from "../../assets/icons/PUCP-IN.svg";
import pucpPhoto from "../../assets/images/pucp.png";
import backArrow from "../../assets/icons/back-arrow.svg";
import threesixty from "../../assets/icons/360.svg";
import './Mapa.css';
import { useNavigate } from 'react-router-dom';
import CircleButton from '../../components/Button/CircleButton';

function Mapa() {
    let navigate = useNavigate();
    const [showMap, setShowMap] = React.useState(false);


    return (
        (showMap &&
            <div className='mapa-container' style={{width: '100vw', height: '100dvh'}}>
                <iframe src="/mapasrc/index.html"  style={{width: '100%', height: '100%'}}></iframe>
            </div>
        )
        ||
        <Auxiliar>
          <Box>
            <Grid container className="titleLayoutPI" alignItems="center">
                <Grid item xs={12} className="imageLogoContainer" justifyContent='center' alignItems='center'>
                  <img src={pucpLogo} className="loginImg" alt="PUCP-IN Logo" />
                  <img
                    src={pucpInCaps}
                    className="loginCaps"
                    alt="PUCP-IN palabra"
                  />
                </Grid>
            </Grid>
            <img src={pucpPhoto} className="pucpPhoto" alt="PUCP-IN" />
            <Grid container className="mapDescription" alignItems="center">
                <Typography variant="body2" className="mapDescriptionTitle">
                    Bienvenido a PUCP-IN, énterate de los anuncios, talleres, eventos y noticias. Descubre los espacios de la universidad y los beneficios que ofrece la PUCP:
                </Typography>
            </Grid>
            <Grid container className="mapDescription" alignItems="center" gap="20px">
                <CircleButton
                    buttonText={'Regresar'}
                    iconButton={backArrow}
                    onClick={() => {navigate(-1)}}
                    hx={"h4"}
                />
                <CircleButton
                    buttonText={'Mapa 360°'}
                    iconButton={threesixty}
                    onClick={() => {setShowMap(true)}}
                    hx={"h4"}
                />
            </Grid>
            <CssBaseline />
          </Box>
        </Auxiliar>
    )
}

export default Mapa;